<template>
  <v-main class="grey lighten-3">
    <router-view />
  </v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {},
  }
</script>
